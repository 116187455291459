import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next'
import {
    Button,
    Grid,
    Paper,
} from '@material-ui/core';


import Tabs, { TabsContainer, TabsPanels as OriginalTabsPanels } from 'components/Tabs'

import { getImagePath } from 'Util/Image';
import { getStringSurfaceDiameter, getStringSurfaceRect, sortedPhotoItems, oeuvreAuthentifiee } from 'Util/Oeuvre';
import { displayOeuvreLastLocalisation } from 'Util/OeuvreLocalisation';
import { displayOeuvreLastProprio, getOeuvreLastProprioId } from 'Util/OeuvreProprietaire';
import { calcMultipleDatas } from 'Util/OeuvreMultiple';
import { getOeuvreStatus } from 'Util/OeuvreStatus';
import { isOeuvreMultiple, typeOeuvreString } from 'Util/Oeuvre'
import { consignationsActives } from 'Util/OeuvreConsignation'
import { getOeuvreElements, getOeuvreMateriaux, getOeuvreCouleurs, getOeuvreFamilles } from 'Util/OeuvreCriteres'
import { trouverDifferences } from 'Util/Divers'

import { useCurrentPermissionProfile, useCurrentUserEmail } from 'redux/hooks';
import Moment from 'moment';

import Accordion from 'components/Accordion'
import Image from 'components/Img'
import FileDownloadButton from 'components/FileDownloadButton'
import Form from 'components/Form';
import PhotoItemsModal from 'components/PhotoItemsModal'
import Swiper from 'components/Swiper'

import AssuranceTable from './Assurance'
import PrixTable from './Prix'
import AuthentificationTable from './Authentification'
import HistoryTable from './History'
import LocalisationTable from './Localisation'
import GlobalLocalisationTable from './GlobalLocalisation'
import OeuvreSoeurPanel from './OeuvreSoeur'
import TransportTable from './Transport'
import MultiplePanel from './Multiple'




const ImageSwiperActionContainer = styled.div`
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1000;
    > * {
        margin-left: 5px;
    }
`

const TabsPanels = styled(OriginalTabsPanels)`
    padding:20px;
    padding-top: 0px;
`

const FormContainer = styled.div`
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`

const Box = styled(Paper)`
    padding:20px;
`

const GridContainerStyled = styled(Grid)`
    width: 100%;
`
const GridContainer = props => (
    <div style={{padding: "15px"}}>
        <GridContainerStyled container spacing={6} {...props} />
    </div>
)


const ImagesSwiperDownloader = ({ archive, images, onClickChange }) => {

    const [ index, setIndex ] = useState(0);
    var currentSrcImage = ""
    var exportFilename = "image.jpg"
    const { t } = useTranslation();

    if ( images && images.length>0 && index<images.length ) {
        currentSrcImage = images[index].filename ? getImagePath(images[index].filename) : null;
        exportFilename = "Oeuvre #" + archive + ".jpg"
    }

    return (
        <>
            <ImageSwiperActionContainer>
                {currentSrcImage &&
                    <FileDownloadButton src={currentSrcImage} filename={exportFilename}>Export</FileDownloadButton>
                }
                {onClickChange && <Button variant="contained" color="primary" onClick={onClickChange}>{t("modifier")}</Button>}
            </ImageSwiperActionContainer>

            {images && images.length > 0 && <Swiper
                onChange={setIndex}
                items={images.map((image, i) => (

                    <Image
                        key={image["filename"]} alt="" filename={image.filename}
                        style={{width: '100%'}}
                        imgStyle={{maxWidth: '100%', maxHeight: '300px'}}
                    />
                ))}
            />}
        </>
    )
}



export default ({ data, reloadData = () => {}, onChange = () => {}, layout = 'editcreate' }) => {

    const { t } = useTranslation();
    const [ modalPhotoItemsOpen, setModalPhotoItemsOpen ] = useState(false);
    const [ selectedMultiples, setSelectedMultiples ] = useState({})
    
    const isMultiple = isOeuvreMultiple(data)

    const permissionProfile = useCurrentPermissionProfile();
    const userEmail = useCurrentUserEmail();

    const userIsComitee = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === null;
    const canSeeMoreTabs = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === 'adriana' || permissionProfile === null || userEmail === "ag.ateliersoto@gmail.com";

    const canEditPhoto = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === null;
    const canEditRightForm = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === null;
    const canEditCode = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'avila' || permissionProfile === null;

    const canEditDescription = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeeOeuvreSoeurs = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeTransport = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeCaracTechniques = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeCodeAvila = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeLocalisation = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    const canSeeConservation = permissionProfile === 'comite' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === 'isabelle' || permissionProfile === 'avila' || permissionProfile === 'regisseur' || permissionProfile === null;
    
    const canSeeObservations = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeeFabrication = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeeHistorique = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin'|| permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === null ;
    const canSeeAuthentification = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeePrix = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === null;
    const canSeeAssurance = permissionProfile === 'comite' || permissionProfile === 'comite_consultation' || permissionProfile === 'admin' || permissionProfile === 'adriana' || permissionProfile === 'acces_lecture' || permissionProfile === null;

    const authentifieComiteSoto = oeuvreAuthentifiee(data)

    const photo_items = sortedPhotoItems(data.photo_items, userIsComitee)
    
    data.typeOeuvreString = typeOeuvreString(data.type)
    
    if ( data.localisations ) {
        data.localisations.sort(function (a, b) {
            return a.date_localisation > b.date_localisation ? -1 : 1;
        });
    }

    if ( data.historiques ) {
        data.historiques.sort(function (a, b) {
            return a.date_historique > b.date_historique ? -1 : 1;
        });
    }

    if ( data.multiples ) {
        data.multiples.sort(function (a, b) {
            if ( a.localisation && b.localisation ) {
                return a.localisation.localeCompare(b.localisation) ? -1 : 1;
            }
            return -1
        });
            data.multiples.sort(function (a, b) {
            return a.numero < b.numero ? -1 : 1;
        });
    }

    if ( isMultiple === true ) {
        calcMultipleDatas(data)
    } else {
        data.dernier_proprietaire_id = getOeuvreLastProprioId(data)

        data.dernier_proprietaire = displayOeuvreLastProprio(data)
        data.derniere_localisation = displayOeuvreLastLocalisation(data)
    }

    var dateofopening = Moment(data.date_ouverture, 'YYYY-MM-DD');
    var today = Moment();
    const isDateOuvertureOneYear =  today.diff(dateofopening, 'days') > 365 ? 1 : 0
    const isReserve = data.statut_reserve ? true : false
    data.formatted_status_reserve_date =  Moment(data.status_reserve_date).format('DD-MM-YYYY')
    
    var dernier_proprietaire_link = "/contact/" + data.dernier_proprietaire_id

    data.poids_de_loeuvre_double = data.poids_de_loeuvre / 100

    if (data.dernier_proprietaire_id === -1 ) {
        dernier_proprietaire_link = "/contact/"
    }

    const consActives = consignationsActives(data)
    var isConsignation = false

    if ( consActives.length > 0 ) {
        const consignationActive = consActives[0]
        let date_debut = Moment(consignationActive.date_debut, "YYYY-MM-DD").format('DD/MM/YYYY')
        let date_fin = Moment(consignationActive.date_fin, "YYYY-MM-DD").format('DD/MM/YYYY')

        var consignationString = consignationActive.motif + " , " + consignationActive.emprunteur + " , " + date_debut + " - " + date_fin
        data.consignation_en_cours = consignationString
        isConsignation = true
    }


    if ( data.observations ) {

        if ( data.observations.startsWith("<p>") ) {
        } else {
            var newString = data.observations
            data.observations = "<p>" + newString.replaceAll('\r', '') +  "</p>" 
        }

        data.observations = data.observations.replace(' & ', '&amp;');
        data.observations_old = data.observations
    } else {
        data.observations = ""
        data.observations_old = data.observations
    }


    if ( data.date_creation ) {
        data.date_creation_format = Moment(data.date_creation).format('DD-MM-YYYY')
    }

    if ( data.date_update ) {
        data.date_update_format = Moment(data.date_update).format('DD-MM-YYYY')
    }


    const layoutIsEdit = layout === 'edit';

    const mainForm = [

        ...(data.alerte_oeuvre ? [
            { label: "ALERTE SUR L'OEUVRE", attr: "alert", type: "display", size: { label: 400, color:"red", textsize:"150%" }},
        ] : [] ),

         ...(data.a_restaurer ? [
            { label: "A restaurer", attr: "alert", type: "display", size: { left:150, label: 400}},
        ] : [] ),

        ...(canEditDescription ? [
            { label: t("nb_archive"), attr: "archive", type: "text", textType: 'int', size: { label: 150, field: 100 }},

            { label: t("title"), attr: "titre", type: "text", size: { label: 150, field: 300 } },
            { label: t("other_title"), attr: "autre_titre", type: "text", size: { label: 150, field: 300 }},
        ] : [
            { label: t("nb_archive"), attr: "archive", type: "display", size: { label: 150, field: 100 }},
            { label: t("title"), attr: "titre", type: "display", size: { label: 150, field: 300 } },
            { label: t("other_title"), attr: "autre_titre", type: "display", size: { label: 150, field: 300 }},
        ]),


        ...(canEditDescription ? [
            [{ label: t("creation_year"), attr: "annee_creation", type: "text", textType: 'int', size: { label: 150, field:60 }},
            { label: "Circa", attr: "circa", type: "checkbox", size: { label: 10, left:30 } },],
        ] : [
            [{ label: t("creation_year"), attr: "annee_creation", type: "display", size: { label: 150, field:60 }},
            { label: "Circa", attr: "circa", type: "checkbox", size: { label: 10, left:30 }, disabled:true  },],
        ]),

        ...(canEditDescription ? [
            [{ label: t("annee_de_realisation"), attr: "annee_realisation", type: "text", textType: 'int', size: { label: 150, field:60 }},
            { label: "Circa", attr: "circa_realisation", type: "checkbox", size: { label: 10, left:30 }},]
        ] : [
            [{ label: t("annee_de_realisation"), attr: "annee_realisation", type: "display", size: { label: 150, field:60 }},
            { label: "Circa", attr: "circa_realisation", type: "checkbox", size: { label: 10, left:30 }, disabled:true },]
        ]),

        ...(canEditDescription ? [
            { label: t("type_doeuvre"), attr: 'type', type: "select", selectType: 'artworktypes', size: { label: 150, field: 300 } },
        ] : [
            { label: t("type_doeuvre"), attr: "typeOeuvreString", type: "display", size: { label: 150, field:60 }},
        ]),

        ...(!isMultiple ? [
           { label: t("proprietaire_actuel"), attr: "dernier_proprietaire", type: "displaylink", link:dernier_proprietaire_link, size: { label: 150, field: 300 } },
            { label: t("localisation_actuelle"), attr: "derniere_localisation", type: "display", size: { label: 150, field: 300 } },
        ] : [

        ] ),

         ...(isReserve ? [
            [
                { label: t("reserve"), attr: "formatted_status_reserve_date", type: "display", size: { label:150}},
                { label: "", attr: "status_reserve_galerie", type: "display", size: { left:0, label: 0}},
            ],

        ] : [] ),
        
         ...(isConsignation ? [
            [
                { label: t("consignation_en_cours"), attr: "", type: "display", size: { label:150}},
                { label: "", attr: "consignation_en_cours", type: "display", size: { field: 350 }}
            ],
        ] : [] ),

        ...(canEditDescription ? [
            { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
            { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
            { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
            { label: t("diametre"), attr: "diametre", type: "text", textType: 'measure', size: { label: 150, field: 300 } },
            { label: t("dimensions_HT"), attr: "dimensions_ht", type: "text", size: { label: 150, field: 300 } },
            { label: t("poids_de_loeuvre"), attr: "poids_de_loeuvre_double", type: "text", textType: 'int', size: { label: 150, field: 100 } },
        ] : [
            { label: t("hauteur"), attr: "hauteur", type: "text", textType: 'measure', size: { label: 150, field: 300 }, disabled:true },
            { label: t("largeur"), attr: "largeur", type: "text", textType: 'measure', size: { label: 150, field: 300 }, disabled:true },
            { label: t("profondeur"), attr: "profondeur", type: "text", textType: 'measure', size: { label: 150, field: 300 }, disabled:true },
            { label: t("diametre"), attr: "diametre", type: "text", textType: 'measure', size: { label: 150, field: 300 }, disabled:true },
            { label: t("dimensions_HT"), attr: "dimensions_ht", type: "display", size: { label: 150, field: 300 } },
            { label: t("poids_de_loeuvre"), attr: "poids_de_loeuvre_double", type: "display", textType: 'int', size: { label: 150, field: 100 } },
        ]),

        ...(isMultiple ? [
            ...(canEditDescription ? [
                { label: t("editeur"), attr: "editer_par", type: "text", size: { label: 150, field: 300 } },
                { label: t("serie"), attr: "serie", type: "text", size: { label: 150, field: 300 } },
                { label: t("tirage"), attr: "tirage", type: "text", size: { label: 150, field: 300 } },
            ] : [
               { label: t("editeur"), attr: "editer_par", type: "display", size: { label: 150, field: 300 } },
                { label: t("serie"), attr: "serie", type: "display", size: { label: 150, field: 300 } },
                { label: t("tirage"), attr: "tirage", type: "display", size: { label: 150, field: 300 } },
            ])
        ] : [] ),
    ]

    const photosForm = [
            { label: "", attr: "empty", type: "display", size: { label: 0, left:0, field:500 } },
        [
            { label: "Video", attr: "video", type: "checkbox", size: { label: 150, left:0, field:50 } },
            { label: "Photo HD", attr: "front_photo_hd", type: "checkbox", size: { label: 0, left:50, field:150 } },
            { label: "Pas de photo HD", attr: "pas_de_front_photo_hd", type: "checkbox", size: { label: 0, left:0, field:150 } }
        ],
    ]


    const rightForm = [

        ...( canEditRightForm ? [

        [
            { label: t("nb_tacos"), attr: "nb_tacos", type: "text", textType: 'int', size: { label: 150, field: 60 } },
            { label: t("nb_varillas"), attr: "nb_varillas", type: "text", textType: 'int', size: { label: 100, field: 60, left:20  } },
            { label: t("nb_tes"), attr: "nb_tes", type: "text", textType: 'int', size: { label: 100, field: 60, left:20  }}
        ],

        { label: t("familles"), attr: "familles_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreFamilles()
            },
            size: { label: 150, field: 350 }
        },

        { label: t("elements"), attr: "elements_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreElements()
            },
            size: { label: 150, field: 350 }
        },

        { label: t("couleurs"), attr: "couleurs_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreCouleurs()
            },
                size: { label: 150, field: 350 }
            },
        ]: [
            [
                { label: t("nb_tacos"), attr: "nb_tacos", type: "display", textType: 'int', size: { label: 150, field: 60 } },
                { label: t("nb_varillas"), attr: "nb_varillas", type: "display", textType: 'int', size: { label: 100, field: 60, left:20  } },
                { label: t("nb_tes"), attr: "nb_tes", type: "display", textType: 'int', size: { label: 100, field: 60, left:20  }}
            ],
            { label: t("familles"), attr: "familles_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags_off',
                },
                size: { label: 150, field: 350 }
            },
            { label: t("elements"), attr: "elements_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags_off',
                },
                size: { label: 150, field: 350 }
            },
            { label: t("couleurs"), attr: "couleurs_array", type: "autocomplete",
                autocomplete: {
                    type: 'local_tags_off',
                },
                    size: { label: 150, field: 350 }
                },
        ]),
        ...( canEditCode ? [
            { label: t("code"), attr: "mots_clefs", type: "text", size: { label: 150, field: 300 } },
        ]:[
                { label: t("code"), attr: "mots_clefs", type: "display", size: { label: 150, field: 300 } },
        ]),
    ]


    const formCodeAvila = [

        { label: t("code_direction"), attr: "code_direction", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_assistant_direction"), attr: "code_assistant_direction", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_regisseur"), attr: "code_regisseur", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("code_assistant_regisseur"), attr: "code_assistant_regie", type: "text", multi: 2, size: { label: 200, field: 600 } },
    ]

    const formTechSpecs = [

        [
            { label: t("fiche_technique"), attr: "fiche_technique", type: "checkbox", size: { label: 200, field: 100 } },
            { label: t("date_fiche_technique"), attr: "date_fiche_technique", type: "dateselector", size: { label: 150, field: 150 }},
        ],

        { label: t("materiaux"), attr: "materiaux_array", type: "autocomplete",
            autocomplete: {
                type: 'local_tags',
                options: getOeuvreMateriaux()
            },
            size: { label: 200, field: 600 }
        },

        { label: t("details_techniques"), attr: "details_techniques", type: "text", multi: 3, size: { label: 200, field: 600 } },
        { label: t("contraintes_dinstallation"), attr: "contraintes_installation", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("materiel_et_equipes_necessaires"), attr: "materiel_necessaire", type: "text", multi: 2, size: { label: 200, field: 600 } },
        { label: t("autres_informations_techniques"), attr: "autres_infos_techniques", type: "text", multi: 2, size: { label: 200, field: 600 } },
    ]




    const conservationForm = [

    ...(!isMultiple ? [
        [
            { label: t("a_restaurer"), attr: "a_restaurer", type: "checkbox", size: { label: 150, field:50 } },
            { label: t("nonrestaurable"), attr: "nonrestaurable", type: "checkbox", size: { label: 150, field:50 } },
            { label: t("detruit"), attr: "detruit", type: "checkbox", size: { label: 150, field:50 } }
        ],


        ...(isDateOuvertureOneYear ? [
            [
                { label: t("date_ouverture"), attr: "date_ouverture", type: "dateselector", size: { label: 150, field: 150 }},
                { label: t("Pas ouvert depuis plus d'un an"),  type: "display", size: { label: 400, color:"red", textsize:"120%"  }}]
            ] : [
                { label: t("date_ouverture"), attr: "date_ouverture", type: "dateselector", size: { label: 150, field: 150 }},
            ]),
        [
            { label: t("date_dernier_constat"), attr: "date_constat", type: "dateselector", size: { label: 150, field: 150 } },
            { label: t("realise_par"), attr: "realise_par", type: "text", size: { label: 150, field: 200 }},
        ],

        { label: t("restauration"), attr: "restauration", type: "text", multi: 5, size: { label: 150, field: 500 } },
        ] :  [
            { label: t("fiche_technique"), attr: "fiche_technique", type: "checkbox", size: { label: 150, field: 100 } },
        ])
    ]



    return (

        <GridContainer>

            <Grid item xs={!layoutIsEdit ? 12 : 6}>
                <Box elevation={1} style={{position: 'relative', height: '100%'}}>
                    <FormContainer>
                        <Form form={mainForm}
                            data={data}
                            onChange={ retour => {
                                retour.poids_de_loeuvre = retour.poids_de_loeuvre_double *100
                                onChange(retour)
                                }
                            }
                        />
                    </FormContainer>
                </Box>
            </Grid>

            {layoutIsEdit && <Grid item xs={6}>

                <Box elevation={1} style={{position: 'relative', height: '100%'}}>

                    { canEditPhoto && 
                    <ImagesSwiperDownloader
                        archive = {data.archive}
                        onClickChange={() => {
                            setModalPhotoItemsOpen(true);
                        }}
                        images={photo_items}
                    /> }

                    { !canEditPhoto && 
                    <ImagesSwiperDownloader
                        archive = {data.archive}

                        images={photo_items}
                    /> }

                    {modalPhotoItemsOpen &&
                        <PhotoItemsModal
                            title={"Photo de " + data.titre}
                            onClose={() => setModalPhotoItemsOpen(false)}
                            photo_items={photo_items}
                            onConfirm={photo_items => {
                               // console.log("PhotoItemsModal : ", photo_items)
                                onChange({
                                    ...data,
                                    photo_items
                                })
                            }}
                        />
                    }
                    <FormContainer>
                        <Form form={photosForm} data={data} onChange={onChange} />
                    </FormContainer>

                    <FormContainer>
                        <Form form={rightForm} data={data} onChange={onChange} />
                    </FormContainer>
                </Box>
            </Grid>}


            <Grid item xs={12}>

                <Accordion
                    accordions={[
                        ...(!layoutIsEdit ? [] : [

                        ...(canSeeOeuvreSoeurs ? [
                        {
                            title: t("oeuvres_soeurs"),
                            content: (
                                <OeuvreSoeurPanel
                                    isComitee={userIsComitee}
                                    data={data}
                                    reloadData={reloadData}
                                    onChange={reloadData}
                                />

                            )
                        },] : []),

                        ...(canSeeCaracTechniques ? [
                        {
                            title: t("caracteristiques_techniques"),
                            expanded: !layoutIsEdit,
                            content: (
                                <FormContainer>
                                    <Form form={formTechSpecs} data={data} onChange={onChange} />
                                </FormContainer>
                            )
                        },] : []),

                        ...(canSeeCodeAvila ? [
                            {
                                title: t("code_avila"),
                                expanded: !layoutIsEdit,
                                content: (
                                    <FormContainer>
                                        <Form form={formCodeAvila} data={data} onChange={onChange} />
                                    </FormContainer>
                                )
                            },] : []),
    
                        ...(isMultiple ? [
                            {
                                title: t("exemplaires"),
                                content: (
                                    <MultiplePanel
                                        selected={selectedMultiples}
                                        onSelect={setSelectedMultiples}
                                        data={data}
                                        reloadData={reloadData}
                                        onChange={reloadData}

                                    />
                                )
                            }] : []),

                        ...(canSeeLocalisation ? [

                        {
                            title: t("localisation"),
                            content: (
                                <LocalisationTable
                                    selectedMultiples={selectedMultiples}
                                    isMultiple={isMultiple}
                                    data={data}
                                    reloadData={reloadData}
                                    onChange={reloadData}
                                />
                            )
                        },] : []),

                        ...(!isMultiple ? [
                            ...(canSeeConservation ? [

                        {
                            title: t("conservation"),
                            content: (
                                <FormContainer>
                                    <Form form={conservationForm} data={data} onChange={onChange} />
                                </FormContainer>
                            )
                        },] : []), ] : []),

                        ...(canSeeTransport ? [

                        {
                            title: t("transport"),
                            content: (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TransportTable
                                            data={data}
                                            isMultiple={isMultiple}
                                            reloadData={reloadData}
                                            onChange={reloadData} 
                                            
                                    />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("passeport"), attr: "passeport", type: "checkbox" },
                                                { label: t("remarques"), attr: "historique", type: "text", multi: 5, size: { label: 150, field: 500 } },
                                            ]} data={data}
                                                onChange={onChange} 
                                                reloadData={reloadData}
                                            />
                                        </FormContainer>
                                    </Grid>
                                </Grid>
                            )
                        }, ] : []),

                        ])
                    ]}
                />
            </Grid>


            {canSeeMoreTabs && <Grid item xs={12}>

                {layoutIsEdit &&
                <TabsContainer orientation={"vertical"}>

                    <Tabs
                        orientation={"vertical"}
                        variant="scrollable"
                        tabs={[

                            ...(canSeeObservations ? [
                            {
                                label: t("observations"),
                                panel: (
                                    <TabsPanels>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    [
                                                        { label: t("etat_de_la_fiche"), attr: "etat_fiche", type: "autocomplete",
                                                            autocomplete: {
                                                                type: 'local_tags',
                                                                options: getOeuvreStatus()
                                                            },
                                                            size: { label: 150, field: 300 }
                                                        },
                                                        { label: t("source"), attr: "source", type: "text", size: { left:50, label: 100, field: 200 }},
                                                    ],

                                                    { label: t("date_de_creation_fiche"), attr: "date_creation_format", type: "display", size: { label: 150, field: 100 } },
                                                    { label: t("date_modification_fiche"), attr: "date_update_format", type: "display" , size: { label: 150, field: 100 }},
                                                    { label: t("doublon"), attr: "doublon", type: "checkbox", size: { label: 150, field: 100 } },
                                                    ]} data={data} onChange={ onChange}
                                                />

                                                <Form form={[
                                                        { label: t("observations"), attr: "observations", type: "richtext", multi: 12, size: { label: 150, field: 600 } },
                                                    ]} data={{...data}} onChange={ retour => {

                                                     //   console.log("retour : ", retour)
 
                                                        if ( retour.observations !== data.observations_old ) {
                                                            console.log("retour observations :", retour.observations)
                                                            console.log("data observations :", data.observations)
                                                            console.log("data observations_old :", data.observations_old)

                                                            console.log(trouverDifferences(retour.observations, data.observations));

                                                            onChange(retour) 
                                                        } else {
                                                            console.log("same string")
                                                        }
                                                    }
                                                }
                                                />
                                            </Grid>
                                        </Grid>

                                    </TabsPanels>
                                )
                            }, ] : []),

                             ...(canSeeHistorique ? [
                           {
                                label: t("historique"),
                                panel: (
                                    <TabsPanels>
                                        <HistoryTable
                                            isMultiple={isMultiple}
                                            selectedMultiples={selectedMultiples}
                                            data={data}
                                            reloadData={reloadData}
                                            onChange={reloadData}
                                        />
                                        <Grid item xs={12}>
                                            <FormContainer>
                                                <Form form={[
                                                        { label: t("remarques"), attr: "remarques_provenance", type: "text", multi: 10, size: { label: 150, field: 500 } },
                                                    ]} data={data} onChange={onChange}
                                                />
                                            </FormContainer>
                                        </Grid>
                                    </TabsPanels>
                                )
                            }, ] : []),

                            ...(canSeeObservations && !isMultiple ? [
                            {
                                label: t("global_localisation"),
                                panel: (
                                    <TabsPanels>
                                        <GlobalLocalisationTable
                                            reloadData={reloadData}
                                            data={data}
                                            selectedMultiples={selectedMultiples}
                                            isMultiple={isMultiple}
                                            onChange={onChange}
                                        />
                                    </TabsPanels>
                                )
                            },] : []),

                             ...(canSeeFabrication ? [
                            {
                                label: t("fabrication"),
                                panel: (
                                    <TabsPanels>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Form form={[
                                                    { label: t("fabrique_par"), attr: "fabrique_par", type: "text", size: { label: 150, field: 300 } },
                                                    { label: t("observations"), attr: "fabrication_observations", type: "text", multi: 10, size: { label: 150, field: 500 } },
                                                    { label: t("plan"), attr: "plan", type: "checkbox", size: { label: 150, field: 100 } },
                                                    { label: t("ft_verifie_par_le_comite"), attr: "fiche_technique_verifie_comite", type: "checkbox", size: { label: 150, field: 100 } },
                                                    { label: t("code_comite"), attr: "code", type: "text", size: { label: 150, field: 300 } },
                                                ]}
                                                    data={data}
                                                    onChange={ data => {
                                                            if ( data.fiche_technique_verifie_comite === 1 ) {
                                                                data.fiche_technique = 1
                                                            }
                                                            onChange(data)
                                                        }
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    </TabsPanels>
                                )
                            }, ] : []),

                             ...(canSeeAuthentification ? [
                            {
                                label: t("authentification"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                ...(authentifieComiteSoto ? [
                                                    { label: "CERTIFICAT COMITE SOTO", attr: "", type: "display", size: { label: 200, field: 200 } }
                                                ] : []),
                                                    { label: t("authentification"), attr: "remarques_authentification", type: "autocomplete",
                                                        autocomplete: {
                                                            type: 'free',
                                                            options: [ "Authentifié par J. Soto", "Authentifié par H. Soto", "A confirmer", "Probable", "Douteux", "Certificat Avila", "Certificat par Soto", "Comité Soto"]
                                                        }, size: { label: 150, field:400 },
                                                    },
                                                    { label: t("alerte_sur_loeuvre"), attr: "alerte_oeuvre", type: "checkbox" , size: { label: 150, field: 100 }},

                                            ]} data={data} onChange={onChange} />
                                        </FormContainer>

                                        <AuthentificationTable
                                            data={data}
                                            reloadData={reloadData}
                                            onChange={reloadData}
                                    />
                                    </TabsPanels>
                                )
                            }, ] : []),

                             ...(canSeePrix ? [
                            {
                                label: t("prix"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("surface"), key:"surface_diameter", type: "display", getValue: data.diametre ? getStringSurfaceDiameter : getStringSurfaceRect },
                                                { label: t("disponible_a_la_vente"), attr: "dispo_a_la_vente", type: "checkbox" , size: { label: 150, field: 100 }},
                                            ]} data={data} onChange={onChange} />
                                        </FormContainer>

                                        <PrixTable
                                            data={data}
                                            reloadData={reloadData}
                                            isMultiple={isMultiple}
                                            selectedMultiples={selectedMultiples}
                                            onChange={reloadData}
                                        />
                                    </TabsPanels>
                                )
                            }, ] : []),

                             ...(canSeeAssurance ? [
                            {
                                label: t("assurance"),
                                panel: (
                                    <TabsPanels>
                                        <FormContainer>
                                            <Form form={[
                                                { label: t("inventaire"), attr: "inventaire", type: "checkbox", size: { label: 150, field: 100 } }
                                            ]} data={data} onChange={onChange} />
                                        </FormContainer>

                                        <AssuranceTable
                                            data={data}
                                            reloadData={reloadData}
                                            onChange={reloadData}
                                        />
                                    </TabsPanels>
                                )
                            }, ] : []),
                        ]}
                    />

                </TabsContainer>}

            </Grid>}


        </GridContainer>

    )
}