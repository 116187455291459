import React, { useEffect, useState } from 'react';

import {
    CircularProgress,
} from '@material-ui/core'


import { getAllDessins } from 'Api';
import PageLayout from 'layouts/Helpers/PageLayout'
import Layout from './layout'
import { sortAlphaNum } from 'Util/Sorting'

export default ({ actions }) => {

    const [ dessins, setDessins ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getAllDessins().then(dessins => {

            var allDessins = dessins.dessins

            allDessins.sort(function (a, b) {
                return sortAlphaNum(a['archive'], b['archive'], true)
            });

            setDessins(allDessins || []);
            setLoading(false);
        })
    }, [])

    if (loading) {
        return <CircularProgress />
    }

    return (

        <PageLayout title={"Dessins"} actions={(
            <div>
            </div>
        )}>
            <Layout data={dessins} actions={actions} onChange={setDessins}/>
        </PageLayout>
    )
}