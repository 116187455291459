
import React, { useState } from 'react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import Img from 'components/Img';
import { Divider, Grid } from '@material-ui/core';
import { FlexRowSpaceBetween } from 'layouts/Helpers/flexbox';

const DialogContent = styled(MuiDialogContent)`

`;

const MainContainer = styled.div`
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
        margin-right: 20px;
    }
`
const MainPictureEditContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
        margin-bottom: 20px;
    }
`


const BlockPicturesContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > * {
        margin-bottom: 20px;
    }
`
const Title = styled.h2`

`

const OtherPicturesContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`
type PhotoItem = {objectid?: string | number, type: string, filename: string};

type Props = {
    title: string,
    onClose: any => any,
    onConfirm: Array<PhotoItem> => any,
    photo_items: Array<PhotoItem>
}

export default ({
    onClose,
    title,
    photo_items,
    onConfirm = () => {}
}: Props) => {


    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [ photoItems: Array<PhotoItem>, setPhotoItems ] = useState(photo_items);

    let face_photo: ?PhotoItem = null;
    let back_photos: Array<PhotoItem> = [];
    let other_photos: Array<PhotoItem> = [];
    
    console.log("photoItems avant: ", photoItems)

    photoItems.forEach(photo_item => {
     //   console.log("photoItems - photo_item : ", photo_item)

        if (!photo_item) {
            return;
        }

        if (photo_item.type === "1") {
            face_photo = photo_item;
       //     return;
        } 
        if (photo_item.type === "2") {
            back_photos.push(photo_item);
       //   return;
        }
        if (photo_item.type === "99") {
            other_photos.push(photo_item);
        }
    
    })

    console.log("photoItems - apres : ", photoItems)
    console.log("photoItems - back_photos : ", back_photos)
    console.log("photoItems - other_photos : ", other_photos)


    const addNewBackPhoto = filename => {

     //   console.log("setPhotoItems avant : ", photoItems)

        setPhotoItems([
            face_photo, ...other_photos,
            ...back_photos,
            {
                filename,
                type: "2"
            }
        ])
     //   console.log("setPhotoItems apres: ", photoItems)
    }

    const deleteBackPhotoItem = index => {
        const new_back_photos = [
            ...back_photos.slice(0, index),
            ...back_photos.slice(index + 1)
        ]
        setPhotoItems([ face_photo, ...new_back_photos, ...other_photos ])
    }

    const addNewOtherPhoto = filename => {

        setPhotoItems([
            face_photo, ...back_photos,
            ...other_photos,
            {
                filename,
                type: "99"
            }
        ])
        console.log("setPhotoItems : ", photoItems)
    }

    const deleteOtherPhotoItem = index => {
        const new_other_photos = [
            ...other_photos.slice(0, index),
            ...other_photos.slice(index + 1)
        ]

        setPhotoItems([ face_photo, ...back_photos, ...new_other_photos ])

    }

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"lg"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
            </DialogTitle>

            <DialogContent>

                <MainContainer>
                    <MainPictureEditContainer>
                    </MainPictureEditContainer>
                </MainContainer>
                
                <OtherPicturesContainer>
                    <Grid container spacing={2}>
                        <BlockPicturesContainer>
                            <Title>Photo de face</Title>
                            <Divider style={{width: '100%'}}/>
                            <Grid key="front" item>
                                <Img
                                    filename={face_photo && face_photo.filename}
                                    onChange={filename => {
                                        setPhotoItems([
                                            {
                                                filename,
                                                private: 0,
                                                type: "1"
                                            },
                                            ...back_photos,
                                            ...other_photos
                                        ])
                                    }}
                                    onClickDelete={() => {
                                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                            setPhotoItems([ ...back_photos, ...other_photos ])
                                        }
                                    }}
                                    style={{width: '100%', minWidth: '300px', minHeight: '200px'}}
                                    imageHeight={200}
                                />
                            </Grid>
                        </BlockPicturesContainer>

                        {back_photos.map((back_photo, index) => (
                        <BlockPicturesContainer>
                            <Title>Photo de dos</Title>
                            <Divider style={{width: '100%'}}/>
                                <Grid key={(back_photo.objectid || back_photo.filename) + "_" + back_photos.length} item>
                                    <Img
                                        filename={back_photo.filename}
                                        onClickDelete={() => {
                                            if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                                deleteBackPhotoItem(index);
                                            }
                                        }}
                                        style={{width: '100%', minWidth: '300px', minHeight: '200px'}}
                                        imageWidth={200}
                                    />
                                </Grid>
                            </BlockPicturesContainer>
                        ))}

                        { back_photos.length < 3 &&
                        <BlockPicturesContainer>
                                <Title>Photo de dos</Title>
                                <Divider style={{width: '100%'}}/>
                                <Grid item>
                                    <Img
                                    key={photoItems.length}
                                    onChangeLabel={"Ajouter une photo"}
                                    onChange={addNewBackPhoto}
                                    style={{width: '100%', minWidth: '300px', minHeight: '200px'}}
                                    imageHeight={200}
                                />
                            </Grid>
                        </BlockPicturesContainer>
                        }       
                    </Grid>

                </OtherPicturesContainer>

                <Divider style={{width: '100%'}}/>

                <OtherPicturesContainer>
                    <FlexRowSpaceBetween>
                        <Title>Images secondaires</Title>
                    </FlexRowSpaceBetween>

                    <Grid container spacing={2}>
                        {other_photos.map((other_photo, index) => (
                            <Grid key={(other_photo.objectid || other_photo.filename) + "_" + other_photos.length} item>
                                <Img
                                    filename={other_photo.filename}
                                    onClickDelete={() => {
                                        if ( window.confirm('Etes vous certain de vouloir l\'effacer?')) {
                                            deleteOtherPhotoItem(index);
                                        }
                                    }}
                                    style={{width: '100%'}}
                                    imageWidth={200}
                                />
                            </Grid>
                        ))}

                        <Grid item>
                            <Img
                                key={photoItems.length}
                                onChangeLabel={"Ajouter une photo"}
                                onChange={addNewOtherPhoto}
                                style={{width: '100%', minWidth: '300px', minHeight: '200px'}}
                                imageHeight={200}
                            />
                        </Grid>
                    </Grid>

                </OtherPicturesContainer>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={() => {
                    onConfirm(photoItems);
                    onClose();
                }}>Valider</Button>
            </DialogActions>
        </Dialog>
    );
}